import Vue from 'vue';
import VueRouter from 'vue-router';
// import BasicLayout from '@/layouts/BasicLayout.vue';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'Home',
    component: Home,
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
