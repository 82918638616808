const getToken = () => {

};

const setToken = () => {

};

const logout = () => {

};

export {
  getToken,
  setToken,
  logout,
};
